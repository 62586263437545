import { fromReadonlyNonEmptyArray, } from "fp-ts/lib/NonEmptyArray";
import jwtDecode from "jwt-decode";
/**
 * Helper function to check whether a ReadonlyArray contains an element.
 */
function includes(array, element) {
    return array.includes(element);
}
/**
 * The `Role` of a user is decided by what role the user has in auth0. There exists a mapping
 * between roles in auth0 and the roles you see here defined in the `hasura-jwt-claim` rule.
 * If you want to add a new role, you need to add it three places:
 *
 * 1. In the auth0 roles section: https://manage.auth0.com/dashboard/eu/test-folq/roles
 * 2. In the auth0 `hasura-jwt-claim` rule, found under
 * https://manage.auth0.com/dashboard/eu/test-folq/rules for the test environment.
 * 3. In the hasura console you have to create the role if you want to be able
 * to use it for row-level access control to the data in the database.
 */
export const HVERDAGSVERKTOY_NAMESPACE = "https://hverdagsverktoy.folq.no/claims";
export const ROLES_CLAIM = "https://hverdagsverktoy.folq.no/roles";
export const FOLQ_ALLOWED_APPS_CLAIM = "x-folq-allowed-apps";
export const HASURA_CLAIMS = "https://hasura.io/jwt/claims";
export const HASURA_ALLOWED_ROLES_CLAIM = "x-hasura-allowed-roles";
export const HASURA_DEFAULT_ROLE_CLAIM = "x-hasura-default-role";
export const HASURA_USER_ID_CLAIM = "x-hasura-user-id";
export const HASURA_ROLE_HEADER = "x-hasura-role";
export const HASURA_ACCOUNT_CLAIM = "x-hasura-account-id";
export const HASURA_UNAUTHORIZED_ROLE = "anonymous";
export const HASURA_ORGANIZATION_ID_CLAIM = "x-hasura-organization-id";
export const HASURA_ACCOUNT_ID_CLAIM = "x-hasura-account-id";
export const ACCESS_TOKEN_LOCAL_STORAGE_KEY = "hverdagsverktoy-access-token";
/**
 * Possible organization roles. This is used to determine which of the users' allowed
 * roles will be used in requests. They are listed in the order of precedence -
 * if the user has multiple of these roles, the first one in the array will
 * be picked.
 */
const ORGANIZATION_ROLES = ["org_owner", "org_admin", "org_user"];
const PERMITTED_ROLES = [...ORGANIZATION_ROLES, "admin", "user"];
const roleIsOrganizationRole = (role) => includes(ORGANIZATION_ROLES, role);
export const allFolqRoles = fromReadonlyNonEmptyArray(PERMITTED_ROLES);
export const claimsFromAccessToken = (accessToken) => jwtDecode(accessToken);
export const getFirstMatchingOrganizationRole = async (accessToken) => {
    const accessTokenClaims = claimsFromAccessToken(accessToken);
    const roles = accessTokenClaims["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"];
    return getFirstMatchingRole(fromReadonlyNonEmptyArray(ORGANIZATION_ROLES), roles.filter(roleIsOrganizationRole));
};
export function getFirstMatchingRole(permittedRoles, currentRoles) {
    return permittedRoles.find((vr) => currentRoles.find((r) => vr === r));
}
export const isFolqAdmin = (userRoles) => userRoles.includes("admin");
export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
export const setAccessToken = (accessToken) => localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, accessToken);
export const useAccessToken = () => {
    const accessToken = getAccessToken();
    if (!accessToken)
        throw Error("No access token available");
    return accessToken;
};
