import { generateTraceParentClientSide } from '@folq/common/lib/util/apiHelpers';

/**
 *
 * @param app
 */
export function initialize(app) {
  app.ports.generateTraceparent.subscribe(() => {
    const traceparent = generateTraceParentClientSide();
    app.ports.traceparent.send(traceparent);
  });
}
