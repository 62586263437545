import mixpanel from "mixpanel-browser";
// Could have gotten these functions from @folq/common/environments, but that would have created a circular dependency, so its not possible.
const isDevelopment = () => process.env.ENV === "development";
const isStaging = () => process.env.ENV === "staging";
const isProd = () => process.env.ENV === "prod";
/**
 *
 * @param projectToken Can be provided directly as parameter or indirectly through NEXT_PUBLIC_MIXPANEL_TOKEN
 */
export const initMixpanel = (projectToken) => {
    if ((isStaging() || isProd()) &&
        !projectToken &&
        !process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
        throw Error("Project token not provided and NEXT_PUBLIC_MIXPANEL_TOKEN is missing");
    }
    return mixpanel.init(projectToken || process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || "", {
        debug: isDevelopment(),
        persistence: "localStorage",
        ignore_dnt: true,
        api_host: "https://api-eu.mixpanel.com", // Route data to Mixpanel's EU servers: https://developer.mixpanel.com/docs/javascript#eu-data-residency
    });
};
