export function initialize(elmApp) {
  elmApp.ports.loadMarkdownEditorCustomElement.subscribe(
    loadMarkdownEditorCustomElement
  );

  let loadState = 'not_loaded';

  async function loadMarkdownEditorCustomElement() {
    if (loadState === 'loaded') {
      window.requestAnimationFrame(
        elmApp.ports.markdownEditorCustomElementLoaded.send
      );
      return;
    } else if (loadState === 'loading') {
      return;
    }

    loadState = 'loading';

    const { EditorView } = await import('prosemirror-view');
    const { EditorState, Plugin } = await import('prosemirror-state');
    const { schema, defaultMarkdownParser, defaultMarkdownSerializer } =
      await import('prosemirror-markdown');
    const { exampleSetup } = await import('prosemirror-example-setup');
    const { toggleMark, setBlockType, lift } = await import(
      'prosemirror-commands'
    );
    const { wrapInList } = await import('prosemirror-schema-list');
    const debounce = await import('debounce');

    function markActive(state, type) {
      let { from, $from, to, empty } = state.selection;
      if (empty) return type.isInSet(state.storedMarks || $from.marks());
      else return state.doc.rangeHasMark(from, to, type);
    }

    const commands = {
      bold: toggleMark(schema.marks.strong),
      italic: toggleMark(schema.marks.em),
      paragraph: setBlockType(schema.nodes.paragraph),
      heading1: setBlockType(schema.nodes.heading, { level: 1 }),
      heading2: setBlockType(schema.nodes.heading, { level: 2 }),
      heading3: setBlockType(schema.nodes.heading, { level: 3 }),
      bulletList: wrapInList(schema.nodes.bullet_list),
      orderedList: wrapInList(schema.nodes.ordered_list),
      lift: lift,
      removeLink: (state, dispatch, view) => {
        if (!state.selection.empty && markActive(state, schema.marks.link)) {
          return toggleMark(schema.marks.link)(state, dispatch, view);
        }
        return false;
      },
      addLink: (state, dispatch, view, attributes) => {
        if (!state.selection.empty) {
          return toggleMark(schema.marks.link, attributes)(
            state,
            dispatch,
            view
          );
        }
        return false;
      },
    };

    window.customElements.define(
      'markdown-editor',
      class extends HTMLElement {
        constructor() {
          super();

          const this_ = this;
          const onUpdatePlugin = new Plugin({
            view() {
              function onDebouncedUpdate() {
                const newValue = defaultMarkdownSerializer.serialize(
                  this_.view.state.doc
                );
                if (newValue !== this_.stringValue) {
                  this_.stringValue = newValue;
                  this_.dispatchEvent(
                    new CustomEvent('value-change', { detail: newValue })
                  );
                }
              }
              const debouncedUpdate = debounce(onDebouncedUpdate, 100);
              return {
                update() {
                  debouncedUpdate();
                  let anyChanged = false;
                  this_.enabledCommands = this_.enabledCommands || {};
                  for (const key in commands) {
                    const isEnabled = commands[key](
                      this_.view.state,
                      null,
                      this_.view
                    );
                    if (isEnabled !== this_.enabledCommands[key]) {
                      anyChanged = true;
                      this_.enabledCommands[key] = isEnabled;
                    }
                  }
                  if (anyChanged) {
                    elmApp.ports.enabledMarkdownEditorCommandsChanged.send({
                      id: this_.getAttribute('id'),
                      enabledCommands: this_.enabledCommands,
                    });
                  }
                },
                destroy() {
                  debouncedUpdate.flush();
                },
              };
            },
          });

          const addMarkdownClassPlugin = new Plugin({
            props: {
              attributes: { class: 'markdown' },
            },
          });

          const customTransformPastedHTMLPlugin = new Plugin({
            props: {
              transformPastedHTML: (html) => {
                // NB! This is a somewhat hacky way of fixing the issue of most pasted text getting both <em> and <strong> tags when being pasted into the editor
                const cleanedHtml = html.replaceAll(/style\=\".*?\"/g, '');
                return cleanedHtml;
              },
            },
          });

          const state = EditorState.create({
            doc: defaultMarkdownParser.parse(''),
            plugins: exampleSetup({ schema, menuBar: false }).concat([
              onUpdatePlugin,
              addMarkdownClassPlugin,
              customTransformPastedHTMLPlugin,
            ]),
          });

          this.editorRoot = document.createElement('div');
          this.editorRoot.classList.add('hf', 'wf', 's', 'e');
          this.view = new EditorView(this.editorRoot, {
            state,
          });
          this.value = '';
        }

        connectedCallback() {
          this.appendChild(this.editorRoot);
        }

        get value() {
          return this.stringValue;
        }

        set value(newValue) {
          if (newValue !== this.stringValue && typeof newValue === 'string') {
            this.stringValue = newValue;
            const doc = defaultMarkdownParser.parse(newValue);
            const newState = EditorState.create({
              schema: this.view.state.schema,
              doc,
              plugins: this.view.state.plugins,
            });
            this.view.updateState(newState);
          }
        }
      }
    );

    elmApp.ports.markdownEditorCommand.subscribe(([id, command, payload]) => {
      const editor = document.getElementById(id);
      if (!editor) {
        return;
      }
      editor.view.focus();
      if (command in commands) {
        commands[command](
          editor.view.state,
          editor.view.dispatch,
          editor.view,
          payload
        );
      }
    });

    loadState = 'loaded';

    window.requestAnimationFrame(
      elmApp.ports.markdownEditorCustomElementLoaded.send
    );
  }
}
