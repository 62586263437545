import { getAccessToken } from "@folq/auth/lib/authorization";
import * as E from "fp-ts/Either";
import { pipe } from "fp-ts/function";
import { formatValidationErrors } from "io-ts-reporters";
import useSWR from "swr";
import { HttpStatusCode } from "../httpStatusCode";
/*
 * Generates an id which can be used for tracing throughout the system which corresponds to W3C specification for distributed tracing from open telemetry.
 * url: https://www.w3.org/TR/trace-context/
 *
 * */
export const generateTraceParentClientSide = () => {
    const generateRandomHex = (bytes) => {
        const values = crypto.getRandomValues(new Uint8Array(bytes));
        return Array.from(values)
            .map((byte) => byte.toString(16).padStart(2, "0"))
            .join("");
    };
    const version = "00";
    const traceId = generateRandomHex(16);
    const parentId = generateRandomHex(8);
    const flags = "01";
    return `${version}-${traceId}-${parentId}-${flags}`;
};
const sensitiveFields = [
    "secret_api_key",
    "cvpartner_api_key",
    "x-oneflow-api-token",
    "x-api-key",
    "authorization",
];
const isString = (value) => typeof value === "string";
export const maskSensitiveFields = (key, value) => {
    if (sensitiveFields.includes(key.toLowerCase()) && isString(value)) {
        return value.replace(/./g, "*");
    }
    return value;
};
const swrAccessTokenFetcher = async ([input, init]) => {
    const traceparent = generateTraceParentClientSide();
    try {
        const fetchResult = await fetch(input, {
            ...init,
            headers: {
                ...init?.headers,
                traceparent: traceparent,
                authorization: `Bearer ${getAccessToken()}`,
            },
        });
        if (!fetchResult.ok) {
            const errorJson = await fetchResult.json();
            if (errorJson.code !== "invalid-jwt")
                throw Error(`Non-OK response status ${fetchResult.status} - ${fetchResult.statusText} from endpoint ${input.toString()} traceParent=${traceparent}`);
        }
        else {
            return await fetchResult.json();
        }
    }
    catch (error) {
        console.warn(error);
        throw error;
    }
};
const swrGeneralFetcher = async ([input, init]) => {
    const traceParent = generateTraceParentClientSide();
    try {
        const fetchResult = await fetch(input, {
            ...init,
            headers: {
                ...init?.headers,
                traceparent: traceParent,
            },
        });
        if (!fetchResult.ok) {
            throw Error(`Non-OK response status ${fetchResult.status} - ${fetchResult.statusText} from endpoint ${input.toString()} traceParent=${traceParent}`);
        }
        return await fetchResult.json();
    }
    catch (error) {
        console.warn(error);
        throw error;
    }
};
export const useAccessTokenSWR = ({ url, extraInit, options, }) => {
    const { data, error, mutate } = useSWR([url, extraInit], swrAccessTokenFetcher, options);
    const loading = !data;
    return { data, mutate, error, loading };
};
export const useGeneralSWR = ({ url, extraInit, options, }) => {
    const { data, error, mutate, isValidating } = useSWR([url, extraInit], swrGeneralFetcher, options);
    const loading = !data;
    return { data, mutate, error, loading, isValidating };
};
// TODO merge with useGeneralSWR when we have a better way to don't show loading when we have cached data
export const useGeneralSWRWithLoading = ({ url, extraInit, options, }) => {
    const { data, error, mutate, isValidating, isLoading } = useSWR([url, extraInit], swrGeneralFetcher, options);
    const loading = isLoading;
    return { data, mutate, error, loading, isValidating };
};
export const decodeValue = (obj, codec) => pipe(codec.decode(obj), E.matchW((errors) => ({
    error: Error(JSON.stringify(formatValidationErrors(errors))),
}), (decoded) => ({ decoded })));
export const redirectToUnauthorized = (res) => {
    res.redirect("/unauthorized").end();
};
export const redirectToLogin = (res) => {
    res.redirect("/api/auth/login").end();
};
export const returnNoContent = (res) => {
    res.status(HttpStatusCode.NO_CONTENT).end();
};
