import { USER_LOST_FORM_FOCUS } from "../events";
import { track } from "./track";
let lastElementBlurred = undefined;
let timeoutId;
export const onFieldBlur = (event) => {
    lastElementBlurred = event.currentTarget.id;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
        lastElementBlurred = undefined;
    }, 4000);
};
export const onWindowBlurred = () => {
    if (lastElementBlurred) {
        track(USER_LOST_FORM_FOCUS, {
            field: lastElementBlurred,
        });
    }
};
