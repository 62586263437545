import { initMixpanel } from '@folq/analytics';
import { track } from '@folq/analytics';
import { MARKETPLACE } from '@folq/analytics/apps';

import { isDevelopment } from '../environment.js';

/**
 *
 * @param app
 */
export function initialize(app) {
  app.ports.initAnalytics.subscribe(function (projectToken) {
    initMixpanel(projectToken);
  });

  app.ports.trackEvent.subscribe(function ({ name, properties }) {
    const props = { ...properties, app: MARKETPLACE };
    isDevelopment
      ? console.debug(
          `Mixpanel event (not logged in development): ${JSON.stringify({
            event: name,
            properties,
          })}`
        )
      : track(name, props);
  });
}
