import { GoogleCalendar, ICalendar, OutlookCalendar } from 'datebook';
import { addHours } from 'date-fns/addHours';

/**
 *
 * @param app
 */
export function initialize(app) {
  app.ports.generateCalendarLinks.subscribe((payload) => {
    const { title, description, attendees } = payload;
    const start = new Date(payload.start);
    const event = {
      title,
      description,
      start,
      end: addHours(start, 1),
      attendees,
    };
    const google = new GoogleCalendar(event);
    const outlook = new OutlookCalendar(event);
    const iCalendar = new ICalendar(event);

    app.ports.calendarLinks.send({
      google: google.render(),
      outlook: `${outlook.render()}&online=1`, // online=1 turns on Skype by default
      iCalendar: iCalendar.render(),
    });
  });
}
