export const AGREEMENT_DRAFT_CREATED = "Agreement Draft Created";
export const AGREEMENT_DRAFT_OPENED = "Agreement Draft Opened";
export const AGREEMENT_DRAFT_SUBMITTED = "Agreement Draft Submitted";
export const INFO_SHEET_VIEWED = "Info Sheet Viewed";
export const PAGE_VIEWED = "Page Viewed";
export const USER_CHANGED_SETTING = "User Changed Setting";
export const USER_LOCKED_HOURS = "User Locked Hours";
export const USER_LOST_FORM_FOCUS = "User Lost Form Focus";
export const USER_UNLOCKED_HOURS = "User Unlocked Hours";
export const USER_LOGGED_HOURS = "User Logged Hours";
export const USER_VIEWED_PROFILE = "User Viewed Profile";
export const USER_DOWNLOADED_CV = "User Downloaded CV";
export const USER_SHARED_PROFILE = "User Shared Profile";
export const USER_SAVED_PROFILE = "User Saved Profile";
